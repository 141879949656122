export function selectSuggestion(
  company,
  searchInput,
  selectedCompany,
  suggestionsContainer,
  fetchOptionsForCompany,
  messageText
) {
  searchInput.value = company.name; // Set company name in the input field
  // Store the selected company object
  selectedCompany.name = company.name;
  selectedCompany.ticker = company.ticker;
  selectedCompany.cik = company.cik;

  suggestionsContainer.innerHTML = ""; // Clear suggestions after selection
  suggestionsContainer.style.display = "none";

  try {
    fetchOptionsForCompany(company.cik); // Fetch options for the selected company using the CIK
  } catch (error) {
    messageText.textContent = "Error fetching data.";
    messageText.style.display = "block";
  }
}
