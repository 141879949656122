import { resetStyles } from "../utils/resetStyles";
export function selectYear(
  year,
  currentValues, // Pass current values directly
  selectedYearValues,
  messageBoxYear,
  yearList
) {
  // If the count reaches 3, reset everything and clear selections
  if (currentValues.countClick === 3) {
    selectedYearValues = []; // Clear the selected year values array
    currentValues.countClick = 0; // Reset the click count
    // Reset state if invalid selection.Remove 'selected' class and reset background color for all selected years.
    resetStyles(currentValues, yearList);

    return { currentValues, selectedYearValues }; // Return the updated state
  }

  // First click: Set selectedStartYear if not already set
  if (currentValues.countClick === 1 && !currentValues.selectedStartYear) {
    currentValues.selectedStartYear = year;
    //startYearLabel.textContent = year;
    return { currentValues, selectedYearValues }; // Return updated values
  }

  // Second click: Set selectedEndYear if valid (year >= selectedStartYear)
  if (currentValues.countClick === 2 && currentValues.selectedStartYear && !currentValues.selectedEndYear) {
    if (year > currentValues.selectedStartYear) {
      currentValues.selectedEndYear = year;
      return { currentValues, selectedYearValues }; // Return updated values
    } else if (year === currentValues.selectedStartYear) {
      currentValues.selectedEndYear = year;
    } else {
      messageBoxYear.textContent = "Second year must be the same or bigger than start year.";
      messageBoxYear.style.display = "block"; // Show error message

      currentValues.countClick = 0;
      // Reset state if invalid selection.Remove 'selected' class and reset background color for all selected years.
      resetStyles(currentValues, yearList);

      return { currentValues, selectedYearValues }; // Return updated values
    }
  }

  // Return updated values
  return { currentValues, selectedYearValues };
}
