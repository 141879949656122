export function selectSuggestionFromElement(
  selectedElement,
  searchInput,
  selectedCompany,
  suggestionsContainer,
  messageBox,
  availableOptions,
  optionsContainer,
  companyOptions,
  currentValues,
  showYearPicker,
  yearPicker,
  yearList,
  messageBoxYear,
  selectedYearValues,
  selectYear,
  fetchOptionsForCompany
) {
  const companyInfo = selectedElement.textContent.split(" - "); // Split name and ticker
  const selectedCurrentCompany = {
    name: companyInfo[0],
    ticker: companyInfo[1],
    cik: selectedElement.getAttribute("data-cik"), // Get the CIK from the data attribute
  };

  searchInput.value = selectedCurrentCompany.name; // Set company name in the input field

  selectedCompany.name = selectedCurrentCompany.name;
  selectedCompany.ticker = selectedCurrentCompany.ticker;
  selectedCompany.cik = selectedCurrentCompany.cik;

  suggestionsContainer.innerHTML = ""; // Clear suggestions after selection
  suggestionsContainer.style.display = "none";

  try {
    fetchOptionsForCompany(
      selectedCompany.cik,
      messageBox,
      availableOptions,
      optionsContainer,
      companyOptions,
      selectedCompany,
      currentValues,
      showYearPicker,
      yearPicker,
      yearList,
      messageBoxYear,
      selectedYearValues,
      selectYear
    );
  } catch (error) {
    messageBox.textContent = "Error fetching data.";
    messageBox.style.display = "block";
  }
}
