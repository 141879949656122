//import { resetBackground } from "../data/selectYear";
import { customReport } from "../excel/customReport";
import { resetStyles } from "../utils/resetStyles";

export async function submitYearRange(
  currentValues,
  selectedCompany,
  makeRequestWithSelection,
  messageBoxYear,
  yearList
) {
  let data = [];

  currentValues.countClick = 0;

  // Check if start year is set and selectedCompany is valid
  if (currentValues.selectedStartYear && selectedCompany && selectedCompany.cik) {
    // Display the message box with loading info, if needed
    messageBoxYear.style.display = "block";
    messageBoxYear.textContent = "Loading... Please wait.";

    try {
      // If endYear is not set, only pass startYear
      data = await makeRequestWithSelection(selectedCompany.cik, currentValues, messageBoxYear);
    } catch (error) {
      messageBoxYear.textContent = "Error fetching data. Please try again.";
    }
    if (!data) {
      messageBoxYear.textContent = "No data available for the selected range.";
      resetStyles(currentValues, yearList);
    } else {
      customReport(data, selectedCompany, messageBoxYear);

      // Hide the message box only after the request is complete
      messageBoxYear.style.display = "none";

      // Reset year selection and options after data is fetched
      resetStyles(currentValues, yearList);
    }
  } else {
    messageBoxYear.style.display = "block";
    messageBoxYear.textContent = "Please select a start year and end year";
  }
}
