/*global fetch */
import { baseUrl } from "../config/config";
export async function fetchSuggestions(
  query,
  suggestionsContainer,
  messageText,
  displaySuggestions,
  selectSuggestion,
  searchInput,
  selectedCompany,
  highlightSelection,
  fetchOptionsForCompany,
  resultsContainer
) {
  if (query.length < 2) {
    suggestionsContainer.innerHTML = ""; // Clear suggestions if input is too short
    suggestionsContainer.style.display = "none";
    return;
  }

  messageText.style.display = "none";
  suggestionsContainer.style.display = "block";

  try {
    const inputNameOrTicker = await fetch(`${baseUrl}company-cik-name?input=${query}`);
    if (inputNameOrTicker.status !== 200) {
      messageText.textContent = "No results found. Please change your search.";
      messageText.style.display = "block";
      resultsContainer.classList.add("hidden");
    }
    const companies = await inputNameOrTicker.json();
    resultsContainer.classList.remove("hidden");
    displaySuggestions(
      companies,
      suggestionsContainer,
      messageText,
      selectSuggestion,
      searchInput,
      selectedCompany,
      fetchOptionsForCompany,
      highlightSelection
    );
  } catch (error) {
    messageText.textContent = "Error fetching data. Please try again.";
    messageText.style.display = "block";
    //suggestionsContainer.innerHTML = "";
    //suggestionsContainer.style.display = "none";
  }
}
