/*global document */

export function showYearPicker(
  selectedYears, // This is the array of selected years
  yearPicker,
  yearList,
  messageBoxYear,
  currentValues,
  selectedYearValues,
  selectYear
) {
  yearPicker.style.display = "block"; // Show the year picker

  // Clear any existing years in the year list
  yearList.innerHTML = "";

  // Iterate over the selected years array
  selectedYears.forEach((year) => {
    const yearItem = document.createElement("div");
    yearItem.classList.add("year-item"); // Add class for styling
    yearItem.textContent = year;
    yearList.appendChild(yearItem);

    // Handle year click
    yearItem.onclick = (e) => {
      messageBoxYear.style.display = "none"; // Hide the message box when a year is selected
      const yearValue = parseInt(e.target.textContent); // Get the actual year value
      currentValues.countClick++; // Increment the click count using currentValues

      // Apply the selection (highlight the selected year)
      e.target.classList.add("selected");
      selectedYearValues.push(yearValue); // Add the actual year value to selectedYearValues

      // Call selectYear with updated values and capture the returned updated state
      const updatedValues = selectYear(
        yearValue, // Actual year value
        currentValues, // Pass the entire currentValues object
        selectedYearValues, // Year values array
        messageBoxYear,
        yearList
      );

      // Update local state based on returned values
      currentValues = updatedValues.currentValues; // Update currentValues
      selectedYearValues = updatedValues.selectedYearValues; // Update selectedYearValues
    };
  });
}
