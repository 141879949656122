/*global document */

export function displaySuggestions(
  companies,
  suggestionsContainer,
  messageText,
  selectSuggestion,
  searchInput,
  selectedCompany,
  fetchOptionsForCompany,
  highlightSelection
) {
  suggestionsContainer.innerHTML = ""; // Clear existing suggestions

  if (companies.length === 0) {
    messageText.textContent = "No results available.";
    messageText.style.display = "block";
  } else {
    messageText.style.display = "none"; // Hide message box when there are suggestions
  }

  companies.forEach((company, index) => {
    const item = document.createElement("div");
    item.classList.add("suggestion-item");
    item.textContent = `${company.name} - ${company.ticker}`;
    item.setAttribute("data-cik", company.cik); // Store CIK in the data attribute
    item.setAttribute("data-index", index); // Store index in the data attribute

    // Click event for each suggestion
    item.onclick = () => {
      // selectSuggestion(company); // Pass the company object when clicked
      selectSuggestion(
        company,
        searchInput,
        selectedCompany,
        suggestionsContainer,
        fetchOptionsForCompany,
        messageText
      );
    };

    suggestionsContainer.appendChild(item);
  });

  highlightSelection(); // Highlight the selected suggestion (if any)
}
