/*global fetch */
import { baseUrl } from "../config/config";

export async function makeRequestWithSelection(company, currentValues, messageBoxYear) {
  try {
    // Construct the URL based on the presence of both start and end years
    let url;
    if (!currentValues.selectedEndYear) {
      // If no selected end year, use only selected start year in the URL
      url = `${baseUrl}combined-report-statement?cik=${company}&statement=${encodeURIComponent(currentValues.currentSelectedStatement)}&start-year=${currentValues.selectedStartYear}`;
    } else {
      // If both selected start and end years are available, include both in the URL
      url = `${baseUrl}combined-report-statement?cik=${company}&statement=${encodeURIComponent(currentValues.currentSelectedStatement)}&start-year=${currentValues.selectedStartYear}&end-year=${currentValues.selectedEndYear}`;
    }

    const response = await fetch(url);
    const data = await response.json();

    // Pass the fetched data to the customReport function
    return data;

    // return data; // Return the fetched data
  } catch (error) {
    // Error handling
    messageBoxYear.textContent = `An error occurred while fetching data: ${error}`;
  }
}
