/*global document fetch */
import { baseUrl } from "../config/config";

export async function showOptionsDropdown(
  options,
  optionsContainer,
  companyOptions,
  selectedCompany,
  currentValues,
  showYearPicker,
  yearPicker,
  yearList,
  messageBoxYear,
  selectedYearValues,
  selectYear
) {
  optionsContainer.style.display = "block"; // Show the options dropdown

  // Clear existing options
  companyOptions.innerHTML = "";

  // Populate dropdown with options based on selected company
  options.forEach((option) => {
    const optionElement = document.createElement("option");
    optionElement.value = option;
    optionElement.textContent = option;
    companyOptions.appendChild(optionElement);
  });

  //   // Add an event listener to the dropdown
  companyOptions.addEventListener("change", async (event) => {
    currentValues.currentSelectedStatement = event.target.value;

    try {
      // Fetch options for the selected company and option
      const result = await fetch(
        `${baseUrl}statements-names?cik=${selectedCompany.cik}&statement-name=${encodeURIComponent(currentValues.currentSelectedStatement)}`
      );

      // Parse the result as JSON
      const selectedStatement = await result.json();
      const selectedYears = Object.values(selectedStatement);

      if (selectedYears[0].length === 1) {
        document.querySelector(".endYear-container").style.display = "none";
        document.querySelector(".startYear-container").style.display = "none";
        currentValues.selectedEndYear = selectedYears[0][0];
      } else {
        document.querySelector(".endYear-container").style.display = "block";
        document.querySelector(".startYear-container").style.display = "block";
        currentValues.selectedEndYear = null;
      }

      showYearPicker(
        selectedYears[0],
        yearPicker,
        yearList,
        messageBoxYear,
        currentValues,
        selectedYearValues,
        selectYear
      );
    } catch (error) {
      messageBoxYear.textContent = `Error fetching data for statement names: ${error}`;
    }
  });

  // Automatically load the years for the first option if available
  if (options.length > 0) {
    currentValues.currentSelectedStatement = options[0]; // Set the first option as the default selected statement

    try {
      // Fetch the first statement's years automatically after the dropdown is populated
      const result = await fetch(
        `${baseUrl}statements-names?cik=${selectedCompany.cik}&statement-name=${encodeURIComponent(currentValues.currentSelectedStatement)}`
      );

      // Parse the result as JSON
      const selectedStatement = await result.json();
      const selectedYears = Object.values(selectedStatement);

      if (selectedYears[0].length === 1) {
        document.querySelector(".endYear-container").style.display = "none";
        document.querySelector(".startYear-container").style.display = "none";
        currentValues.selectedEndYear = selectedYears[0][0];
      } else {
        document.querySelector(".endYear-container").style.display = "block";
        document.querySelector(".startYear-container").style.display = "block";
        currentValues.selectedEndYear = null;
      }

      // Show the year picker with the first and last year
      showYearPicker(
        selectedYears[0],
        yearPicker,
        yearList,
        messageBoxYear,
        currentValues,
        selectedYearValues,
        selectYear
      );
    } catch (error) {
      messageBoxYear.textContent = `Error fetching data for first statement: ${error}`;
    }
  }
}
