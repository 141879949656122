/*global Excel */

export async function customReport(data, selectedCompany, messageBox) {
  const statementName = data.statementName;
  const rows = data.rowDtos || [];
  const rowDtos = rows.map((item) => item.cellValue || []);
  if (rowDtos.length === 0) {
    messageBox.textContent = "No data rows available";
    return;
  }

  // rowDtos.unshift(selectedCompany.name);
  await Excel.run(async (context) => {
    try {
      // const newWorksheet = context.workbook.worksheets.add();
      // newWorksheet.activate();
      const currentWorksheet = context.workbook.worksheets.getActiveWorksheet();
      // Clear the content of the entire worksheet
      currentWorksheet.getRange().clear();

      let startCell = currentWorksheet.getRange("A1");
      let rowCount = rowDtos.length;
      let columnCount = rowDtos[0].length;

      const headerRow = new Array(columnCount).fill(""); // Create an array of empty strings with length equal to columnCount

      // Set the first element to the selectedCompany.name
      headerRow[0] = selectedCompany.name;
      headerRow[1] = statementName;

      // Optionally, you can prepend this header row to rowDtos if you want to include it in the result
      rowDtos.unshift(headerRow); // Add the header row at the beginning

      let range = startCell.getResizedRange(rowCount, columnCount - 1);
      // Set the values of the range
      range.values = rowDtos;
      range.getRow(0).format.font.bold = true;
      await context.sync();
    } catch (error) {
      messageBox.textContent = `No available statements. (${error})`;
      messageBox.style.display = "block";
    }
  });
}
